<template lang="pug">
  v-tooltip(top)
    template(v-slot:activator='{ on, attrs }')
      .d-inline-block(v-bind='attrs', v-on='on')
        slot
    span {{ text }}
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    text: String,
  },
};
</script>

<style scoped></style>
